import React from "react";
import { getCurrentLangKey } from "ptz-i18n";
import { StaticQuery, graphql } from "gatsby";
import { IntlProvider } from "react-intl";
import "intl";

const Layout = ({ children, location, i18nMessages }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={(data) => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);

        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            {children}
          </IntlProvider>
        );
      }}
    />
  );
};

// Layout.propTypes = {
//   children: PropTypes.func,
// }

export default Layout;
