import React, { useState } from "react";
import { Link } from "gatsby";
import Seo from "./seo";
import * as css from "./Frontpage.module.scss";
import IndexPageImages from "./parts/IndexPageImages";
import Greetings from "./parts/Greetings";
import Header from "./header/Header";
import BigTitle from "./home/BigTitle";
import { FormattedMessage } from "react-intl";

// markup
const Frontpage = (props) => {
  const [titleVisible, setTitleVisible] = useState(`true`);
  const data = props.data;

  let sectionEls = [];

  for (let i = 0; i < data.length; i += 1) {
    const entry = data[i].node.frontmatter;

    const sectionEl = (
      <div key={i} className={css.grid__item}>
        <div className={css.linkItem}>
          <Link to={data[i].node.fields.slug}>
            <div className={css.linkItem__textBox}>
              <h2>
                {i + 1}. {entry.title}
              </h2>
              <p>
                <FormattedMessage
                  id="index.textBy"
                  values={{
                    author: entry.authorShort,
                  }}
                />

                {props.lang === "en" ? (
                  <span className={css.pages}>･{entry.pages.length}pages</span>
                ) : (
                  <span className={css.pages}>･{entry.pages.length}ページ</span>
                )}
                <br />
                <FormattedMessage
                  id="index.handwritingBy"
                  values={{
                    interpreterName: entry.interpreter.name,
                    interpreterArea: entry.interpreter.area,
                  }}
                />{" "}
                <span className={css.flag}>{entry.interpreter.flag}</span>
              </p>
            </div>

            <div className={css.linkItem__imageBox}>
              <IndexPageImages entry={entry} />
            </div>
          </Link>
        </div>
      </div>
    );
    sectionEls.push(sectionEl);
  }

  return (
    <main>
      <Seo title={`Home`} lang={props.lang} />

      {titleVisible && (
        <div className={css.cover}>
          <div className={css.coverTitle}>
            <BigTitle
              lang={props.lang}
              onCloseEntrance={() => {
                setTitleVisible(false);
              }}
            />
          </div>
          <div className={css.coverBG} />
        </div>
      )}
      <div>
        <Header lang={props.lang} />
      </div>
      <section className={css.content}>
        <div className={css.title}>
          {props.lang === "en" ? (
            <h3>
              The Index of Reports<span>･{sectionEls.length} reports</span>
            </h3>
          ) : (
            <h3>
              目次<span>･{sectionEls.length}レポート</span>
            </h3>
          )}
        </div>

        <div className={css.grid}>
          <div className={css.grid__item}>
            {<Greetings lang={props.lang} />}
          </div>
          {sectionEls}
        </div>
      </section>

    </main>
  );
};

export default Frontpage;
