// extracted by mini-css-extract-plugin
export var hgwIconArrowDown = "BigTitle-module--hgwIcon-arrow-down--3rvg5";
export var hgwIconArrowUp = "BigTitle-module--hgwIcon-arrow-up--3Btmo";
export var base = "BigTitle-module--base--15hVA";
export var fadeOut = "BigTitle-module--fadeOut--1EhJm";
export var title = "BigTitle-module--title--3RwNc";
export var fadeIn = "BigTitle-module--fadeIn--2XyD2";
export var sub = "BigTitle-module--sub--1LVfs";
export var sub__inner = "BigTitle-module--sub__inner--CxulF";
export var transparentEnterButton = "BigTitle-module--transparentEnterButton--1218h";
export var animationBar = "BigTitle-module--animationBar--3nfCS";
export var animationBarTypeA = "BigTitle-module--animationBarTypeA--3adjG";
export var animBarTypeA = "BigTitle-module--animBarTypeA--2itS2";
export var animationBarTypeB = "BigTitle-module--animationBarTypeB--36LA8";
export var animBarTypeB = "BigTitle-module--animBarTypeB--3oryP";
export var animationBarTypeC = "BigTitle-module--animationBarTypeC--3ACmd";
export var animBarTypeC = "BigTitle-module--animBarTypeC--eGHwW";
export var animationBarTypeD = "BigTitle-module--animationBarTypeD--1wos1";
export var animBarTypeD = "BigTitle-module--animBarTypeD--11S4s";
export var borderWrap = "BigTitle-module--borderWrap--N-Gd2";