import Img from "gatsby-image";
import * as css from "./IndexPageImages.module.scss";
import React, { useEffect, useState } from "react";

const IndexPageImages = (props) => {
  const entry = props.entry;
  const [pageImages, setPageImages] = useState([]);
  const maxPages = 3;
  useEffect(() => {
    const doResize = () => {
      let arrEls = [];
      let lastRotate = null;
      let rotate = null;
      let max = entry.pages.length > maxPages ? maxPages : entry.pages.length;

      // 初期角度と増加量を設定
      const initialAngle = -2;
      const angleIncrement = 2;
      
      for (let j = max-1; j >= 0; j -= 1) {
        let index = max - j;
        rotate = (index % 2 === 0 ? 1 : -1) * (Math.floor(index / 2) + 1) * initialAngle;

        const theStyle = {
          transform: `translate(0, 0) rotate(${rotate}deg)`,
        };

        const isOnlyOne = entry.pages.length <= 1 ? " isOnlyOne" : "";

        arrEls.push(
          <div
            className={css.indexThumbBox__item + ` ${isOnlyOne}`}
            key={`img-${j}-${new Date().toString()}`}
            style={theStyle}
          >
            <Img fluid={entry.pages[j].image.childImageSharp.fluid} alt="" loading="eager" />
          </div>
        );
      }
      setPageImages(arrEls);
    };


    function debounce(func, delay) {
      let debounceTimer;
      return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    }

// デバウンス関数を使用してリサイズイベントをハンドル
    window.addEventListener("resize", debounce(doResize, 1000));
    doResize();
  }, [entry, setPageImages]);

  return <div className={css.indexThumbBox}>{pageImages}</div>;
};

export default IndexPageImages;
