import React from "react";
import Teresa from "../../images/teresa.jpg";
import Seira from "../../images/uchida.jpg";
import * as css from "./Greetings.module.scss";
import { FormattedMessage } from "react-intl";

const Greetings: React.FC<{ lang: "ja" | "en" }> = (props) => {
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.borderWrap}>
          <div />
          <div />
          <div />
          <div />
        </div>

        <div className={css.aboutBox}>
          <div className={css.aboutBox__profile}>
            <div className={css.aboutBox__profile__item}>
              <img
                src={Teresa}
                alt={``}
                className={css.aboutBox__profile__image}
              />
              <div className={css.aboutBox__profile__name + ` ` + css.isJa}>
                <span>
                  <FormattedMessage id={`artist.teresa.name`} />
                </span>
                <br />
                <small>
                  <FormattedMessage id={`artist.teresa.role`} />
                </small>
              </div>
            </div>
            <div className={css.aboutBox__profile__item}>
              <img
                src={Seira}
                alt={``}
                className={css.aboutBox__profile__image}
              />

              <div
                className={
                  css.aboutBox__profile__name +
                  ` ${props.lang === "ja" && css.isJa}`
                }
              >
                <span>
                  <FormattedMessage id={`artist.uchida.name`} />
                </span>
                <br />
                <small>
                  <FormattedMessage id={`artist.uchida.role`} />
                </small>
              </div>
            </div>
          </div>
          <p>
            <FormattedMessage id={`statement.description`} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Greetings;
