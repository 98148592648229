// extracted by mini-css-extract-plugin
export var hgwIconArrowDown = "Greetings-module--hgwIcon-arrow-down--2mvE0";
export var hgwIconArrowUp = "Greetings-module--hgwIcon-arrow-up--1Nzmz";
export var wrapper = "Greetings-module--wrapper--3AGtg";
export var content = "Greetings-module--content--2x-vd";
export var borderWrap = "Greetings-module--borderWrap--2JXa0";
export var animBarTypeA = "Greetings-module--animBarTypeA--3m-p7";
export var animBarTypeB = "Greetings-module--animBarTypeB--3FqWZ";
export var animBarTypeC = "Greetings-module--animBarTypeC--3KsMR";
export var animBarTypeD = "Greetings-module--animBarTypeD--1Kt1x";
export var aboutBox = "Greetings-module--aboutBox--1-1Mj";
export var aboutBox__profile = "Greetings-module--aboutBox__profile--Tj73R";
export var aboutBox__profile__item = "Greetings-module--aboutBox__profile__item--1_VcJ";
export var aboutBox__profile__image = "Greetings-module--aboutBox__profile__image--RAwvs";
export var aboutBox__profile__name = "Greetings-module--aboutBox__profile__name--1iv8X";
export var isJa = "Greetings-module--isJa--TyQAN";